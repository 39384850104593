<template>
  <Layout>
    <BHeader>
      <el-form
        ref="form"
        :inline="true"
        :model="formData"
      >
        <el-form-item>
          <el-input
            v-model="formData.block"
            clearable
            placeholder="订单编号/用户ID"
            @keyup.enter.native="handleSearch"
          />
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="formData.orderStatus"
            placeholder="订单状态"
            multiple
            collapse-tags
            clearable
          >
            <el-option
              v-for="(item, index) in ordersStatusList"
              :key="index"
              :label="item.name"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="formData.sendStatus"
            placeholder="发货状态"
            clearable
          >
            <el-option
              v-for="(item, index) in sendStatusList"
              :key="index"
              :label="item.name"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <!-- <el-form-item>
          <el-select
            v-model="formData.goodsType"
            placeholder="商品类型"
            clearable
          >
            <el-option
              v-for="(item, index) in goodsTypeList"
              :key="index"
              :label="item.name"
              :value="item.value"
            />
          </el-select>
        </el-form-item> -->
        <el-form-item>
          <el-select
            v-model="formData.orderSource"
            placeholder="订单来源"
            clearable
          >
            <el-option
              v-for="(item, index) in orderSourceList"
              :key="index"
              :label="item.name"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="消费时间(世界时间UTC时间)">
          <el-date-picker
            v-model="datetimerange"
            type="datetimerange"
            :clearable="false"
            :default-time="['00:00:00', '23:59:59']"
            value-format="yyyy-MM-dd HH:mm:ss"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          />
        </el-form-item>
      </el-form>
      <template v-slot:right>
        <el-button
          type="primary"
          @click="handleSearch"
        >
          搜索
        </el-button>
      </template>
    </BHeader>
    <el-table
      v-loading="orderListInfo.loading"
      :data="orderListInfo.list"
      stripe
    >
      <el-table-column
        prop="orderId"
        label="订单编号"
        width="170"
      />
      <el-table-column
        prop="goodsId"
        label="商品ID"
        width="170"
      />
      <el-table-column
        prop="gpa"
        label="GPA单号"
        min-width="240"
      >
        <template v-slot="{row}">
          {{ row.gpa || '无' }}
        </template>
      </el-table-column>
      <el-table-column
        prop="userId"
        label="用户ID"
        width="170"
      />
      <el-table-column
        prop="goodsName"
        label="商品"
        width="150"
      />
      <el-table-column
        prop="orderStatus"
        label="交易状态"
        min-width="100"
      >
        <template v-slot="{row}">
          {{ (ordersStatusMap[row.orderStatus] || {}).name || '未知' }}
        </template>
      </el-table-column>
      <el-table-column
        prop="total"
        label="总计"
        min-width="100"
      >
        <template>
          TODO
        </template>
      </el-table-column>
      <el-table-column
        prop="price"
        label="定价"
        min-width="100"
      />
      <el-table-column
        prop="tax"
        label="税额"
        min-width="100"
      >
        <template>
          TODO
        </template>
      </el-table-column>
      <el-table-column
        prop="currency"
        label="货币类型"
        min-width="100"
      />
      <el-table-column
        prop="goodsType"
        label="商品类型"
        width="140"
      >
        <template v-slot="{row}">
          {{ (goodsTypesMap[row.goodsType] || {}).name || '未知' }}
        </template>
      </el-table-column>
      <el-table-column
        prop="tradeTime"
        label="交易时间(世界时间UTC时间)"
        width="200"
      />
    </el-table>
    <Pagination
      :total="orderListInfo.total"
      :page.sync="formData.pageNum"
      :limit.sync="formData.pageSize"
      @pagination="queryOrderList(formData)"
    />
  </Layout>
</template>

<script>
import {
  ordersStatusList,
  goodsTypeList,
  orderSourceList,
  sendStatusList,
  goodsTypesMap,
  ordersStatusMap
} from '@/utils/selectOptions'
import { queryOrderList } from '@/api/trade-management'
import { getYesterdaySection } from '@/utils'

export default {
  name: 'OrderList',
  data () {
    return {
      ordersStatusList,
      ordersStatusMap,
      goodsTypeList,
      orderSourceList,
      sendStatusList,
      goodsTypesMap,
      formData: {
        orderStatus: [3, 7],
        sendStatus: 3,
        pageNum: 1,
        pageSize: 20
      },
      datetimerange: getYesterdaySection(),
      orderListInfo: {
        loading: false,
        total: 0,
        list: []
      }
    }
  },
  created () {
    this.queryOrderList()
  },
  methods: {
    handleSearch () {
      this.formData.pageNum = 1
      this.queryOrderList()
    },
    queryOrderList () {
      this.orderListInfo.loading = true
      let startTime
      let endTime
      if (this.datetimerange && this.datetimerange.length) {
        startTime = this.datetimerange[0]
        endTime = this.datetimerange[1]
      }
      queryOrderList({
        ...this.formData,
        startTime,
        endTime
      })
        .then(res => {
          this.orderListInfo.list = res.data.list
          this.orderListInfo.total = res.data.total
        })
        .finally(() => {
          this.orderListInfo.loading = false
        })
    }
  }
}
</script>

<style scoped lang="less">

</style>
